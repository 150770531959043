<template>
    <q-page class="row" style="display: flex; justify-content: space-evenly;">

    <div class="container col-md-8" style="margin-top: 50px;">
        <div class="row">

            <div class="col-9 content-column" style="padding-left: 100px;">

                <q-card class="news">
                    
                    <img src="/img/irl/2024/amis_faits_en_chemin.JPG">

                    <q-card-section>
                        <div class="text-h6" style="display: flex; justify-content: center;">IRL Château Baillant</div>
                    </q-card-section>

                    <q-card-section class="q-pt-none" style="display: flex; justify-content: center; text-align: center;">
                        <span>Du 31 mai au 5 juin 2024 s’est déroulée notre IRL annuelle au Château Baillant, pour célébrer les 15 ans de Mandragore.<br>Au programme, un grand jeu, des coins, de la piscine, du jacuzzi et surtout des jeux de société.</span>
                    </q-card-section>

                    <div style="display: flex; justify-content: center; padding-bottom: 20px;">
                        <q-btn label="Voir la page" class="btn" color="primary" :to="{name: 'Event2024'}" style="margin-top:15px;" />
                    </div>

                    <q-space />
                </q-card>
            </div>

            <div class="col-md-2 fixed-column" style="display: flex; align-items: center;">

                <q-card class="side-news">

                    <q-card-section style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <div class="text-h6">Évéments à venir</div>
                    </q-card-section>

                    <q-card-section class="q-pt-none" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <div style="display:flex; justify-content: center; font-size: 20px;">IRL 2025</div>
                        Du vendredi 19 au mercredi 24 septembre 2025
                        <q-btn label="Plus d'informations" class="btn" color="primary" href="https://mandragore.tv/event/81db35e0-e5db-4c2a-a346-6eed3b8721ab" style="margin-top:15px;" />
                    </q-card-section>

                </q-card>

            </div>
        </div>
    </div>


</q-page>
</template>

<style scoped>

.side-news {
    padding: 20px;
}

.fixed-column {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      width: 25%;
      z-index: 1000; /* Ensure it stays on top */
    }
    
    .content-column {
      margin-right: 25%; /* Should match the width of the fixed column */
    }

</style>